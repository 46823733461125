@import '~theme/variables';
@import '~theme/font-family';

body {
  font-size: 1rem;
  line-height: 1rem;
  font-family: $font-family;
  background-color: $surfaces-background;
  letter-spacing: $letter-spacing;
}

button {
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  outline: 0;
  letter-spacing: $letter-spacing;
}
