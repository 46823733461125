@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/roboto-reg/Roboto-Regular.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoBold';
  src: url('/assets/fonts/roboto-bold/Roboto-Bold.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoLight';
  src: url('/assets/fonts/roboto-light/Roboto-Light.ttf');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RobotoMedium';
  src: url('/assets/fonts/roboto-medium/Roboto-Medium.ttf');
  font-style: normal;
  font-display: swap;
}
